import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { StackingModal } from "@share/modules/stacking-modals/models/stacking-modal.model";
import { StackingModalStatus } from "@share/modules/stacking-modals/models/stacking-modal-status.enum";
import { LoggerService } from "@services/logger-service.service";

@Injectable({
  providedIn: "root"
})
export class StackingModalsService {
  private modalsSource: BehaviorSubject<StackingModal[]> = new BehaviorSubject([]);
  private closeModalSource: ReplaySubject<string> = new ReplaySubject();

  modals$: Observable<StackingModal[]> = this.modalsSource.asObservable();
  closeModal$: Observable<string> = this.closeModalSource.asObservable();

  constructor(private logger: LoggerService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  openModal(modal: StackingModal): void {
    const modals: StackingModal[] = this.modalsSource.getValue();
    let isCreated: boolean = false;
    modals.map((_modal: StackingModal) => {
      if (_modal.id === modal.id) {
        isCreated = true;
        _modal.content = modal.content
        _modal.title = modal.title
        _modal.status = StackingModalStatus.OPEN;
        _modal.isChanged.next(modal.taskUrl);
        _modal.componentType = modal.componentType;
        _modal.componentData = modal.componentData;
      }
    });
    if (isCreated) {
      this.modalsSource.next(modals);
    } else {
      this.createModal(modal);
    }
  }

  reopenModal(id: string): void {
    const modals: StackingModal[] = this.modalsSource.getValue();
    const modal: StackingModal = modals.find(modal => modal.id === id);
    if (modal) {
      modal.status = StackingModalStatus.OPEN;
      this.modalsSource.next(modals);
    }
  }

  createModal(modal: StackingModal): void {
    const modals: StackingModal[] = this.modalsSource.getValue();
    modals.push(modal);
    this.modalsSource.next(modals);
  }

  hideModal(id: string): void {
    const modals: StackingModal[] = this.modalsSource.getValue();
    modals.map((modal: StackingModal) => modal.status = StackingModalStatus.CLOSED);
    this.modalsSource.next(modals);
  }

  closeModal(id: string): void {
    this.closeModalSource.next(id);
  }

  destroyModal(id: string): void {
    const modals: StackingModal[] = this.modalsSource.getValue();
    const filteredModals: StackingModal[] = modals.filter((modal: StackingModal) => modal.id != id);
    this.modalsSource.next(filteredModals);
  }
}
